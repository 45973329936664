<template>
  <v-container fluid class="pa-0">
    <v-row
      class="my-0 my-sm-5 my-md-16"
      justify="center"
      align="center"
      style="min-height: 65vh"
    >
      <v-col cols="12" sm="10">
        <v-form ref="billingForm" v-model="billingFormValid" lazy-validation>
          <v-row class="mx-2">
            <v-col cols="12" class="mb-3">
              <h1 class="d-flex justify-center font-weight-bold font-size-lg">
                Generar o descargar tu factura
              </h1>
            </v-col>
            <v-col cols="12">
              <p class="title red--text text-center">
                <!--La factura de esta transacción ya ha sido generada-->
                {{ mainErrorMessage }}
              </p>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-text-field
                v-model="billingData.orderId"
                label="Id de transacción"
                filled
                rounded
                maxlength="24"
                :hint="
                  validOrderId == 'VALID' ? 'Id de transacción válido' : ''
                "
                :persistent-hint="validOrderId == 'VALID' ? true : false"
                :background-color="
                  validOrderId == 'VALID' ? '#def3d5' : undefined
                "
                :class="validOrderId == 'VALID' ? 'green--text' : ''"
                :rules="[
                  ...billinIdRules,
                  validOrderId != 'INVALID' ||
                    'El id de la transacción no es váildo',
                ]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-tooltip v-model="toolTipShow" top max-width="400">
                <template v-slot:activator>
                  <v-text-field
                    @click:append="toolTipShow = !toolTipShow"
                    append-icon="mdi-help-circle"
                    :disabled="!(validOrderId == 'VALID')"
                    v-model="billingData.legalName"
                    label="Razón Social"
                    filled
                    rounded
                    :rules="legalNameRules"
                  />
                </template>
                <span
                  >Debe escribirse en mayúsculas, sin acentos, y en caso de
                  persona moral, sin régimen societario (por ejemplo sin S.A. de
                  C.V.).</span
                >
              </v-tooltip>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-text-field
                :disabled="!(validOrderId == 'VALID')"
                v-model="billingData.taxId"
                label="RFC"
                filled
                rounded
                maxlength="13"
                :rules="taxIdRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-text-field
                :disabled="!(validOrderId == 'VALID')"
                v-model="billingData.email"
                label="E-mail"
                filled
                rounded
                :rules="emailRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-text-field
                :disabled="!(validOrderId == 'VALID')"
                v-model="billingData.street"
                label="Calle"
                filled
                rounded
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-text-field
                :disabled="!(validOrderId == 'VALID')"
                v-model="billingData.zipCode"
                type="number"
                label="Código postal"
                filled
                rounded
                :rules="postalCodeRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-text-field
                :disabled="!(validOrderId == 'VALID')"
                v-model="billingData.exterior"
                label="Número Exterior"
                filled
                rounded
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-text-field
                :disabled="!(validOrderId == 'VALID')"
                v-model="billingData.interior"
                label="Número Interior"
                filled
                rounded
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-text-field
                :disabled="!(validOrderId == 'VALID')"
                v-model="billingData.neighborhood"
                label="Colonia"
                filled
                rounded
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-text-field
                :disabled="!(validOrderId == 'VALID')"
                v-model="billingData.municipality"
                label="Municipio"
                filled
                rounded
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-text-field
                :disabled="!(validOrderId == 'VALID')"
                v-model="billingData.state"
                label="Estado"
                filled
                rounded
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-select
                v-model="billingData.paymentMethod"
                label="Método de pago"
                :disabled="disablePaymentMethod || !(validOrderId == 'VALID')"
                filled
                rounded
                :items="PaymentMethodsList"
                :rules="paymentMethodRules"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-select
                v-model="billingData.paymentForm"
                label="Forma de pago"
                :disabled="!(validOrderId == 'VALID')"
                filled
                rounded
                :items="PaymentFormsList"
                :rules="paymentFormRules"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-select
                v-model="billingData.cfdi"
                :disabled="!(validOrderId == 'VALID')"
                label="Uso de CFDI"
                filled
                rounded
                :items="UsoCFDIItems"
                :rules="cfdiRules"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-select
                v-model="billingData.rf"
                :disabled="!(validOrderId == 'VALID')"
                label="Regimen Fiscal"
                filled
                rounded
                :items="fiscalRegimenItems"
                :rules="fiscalRegimenRules"
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
        <v-row class="mx-2 mb-5 mb-sm-10">
          <v-col
            cols="12"
            :class="$vuetify.breakpoint.smAndUp ? 'd-flex justify-end' : ''"
          >
            <v-btn
              rounded
              elevation="0"
              large
              color="#edaa04"
              class="confirm-button"
              @click="createInvoice"
              :disabled="
                !billingFormValid ||
                billingData.orderId == '' ||
                billingData.legalName == '' ||
                billingData.rf == '' ||
                billingData.taxId == '' ||
                billingData.zipCode == '' ||
                billingData.paymentMethod == '' ||
                billingData.paymentForm == '' ||
                billingData.cfdi == '' ||
                billingData.email == '' ||
                billingData.paymentMethod == '' ||
                billingData.paymentForm == '' ||
                billingData.cfdi == ''
              "
            >
              Generar factura
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <landing-footer></landing-footer>
  </v-container>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import ObjectId from "valid-objectid";

import LandingFooter from "@/components/landing/LandingFooter";
import { validations } from "@/share/validations";
import ActionButton from "@/components/shared/ActionButton";
import Endpoints from "@/share/Endpoints";
import { fiscalRegimenItems, UsoCFDIItems } from "@/utils/fiscalRegimen";
import { PaymentMethodsList, PaymentFormsList, CFDIList } from "./constants";
import Vue from "vue";

export default {
  components: {
    LandingFooter,
    ActionButton,
  },
  data() {
    return {
      fiscalRegimenItems,
      UsoCFDIItems,
      toolTipShow: false,
      billingFormValid: false,
      PaymentMethodsList,
      PaymentFormsList,
      CFDIList,
      mainErrorMessage: "",
      validOrderId: "UNDEFINED", // UNDEFINED, VALID, INVALID
      disablePaymentMethod: false,
      billingData: {
        rf: "",
        orderId: "",
        legalName: "",
        taxId: "",
        street: "",
        zipCode: "",
        paymentForm: "",
        paymentMethod: "",
        cfdi: "",
        email: "",
        exterior: "",
        interior: "",
        neighborhood: "",
        municipality: "",
        state: "",
      },
      //Validaciones
      billinIdRules: validations.generalMValidation({
        required: true,
        name: "id de la transacción",
        minLength: 23,
      }),
      legalNameRules: validations.generalFValidation({
        required: true,
        name: "razón social",
      }),
      taxIdRules: validations.generalMValidation({
        required: true,
        name: "RFC",
        minLength: 12,
        maxLength: 13,
      }),
      adressRules: validations.generalFValidation({
        required: true,
        name: "dirección",
      }),
      postalCodeRules: validations.generalMValidation({
        required: true,
        name: "código postal",
        minLength: 5,
      }),
      paymentFormRules: validations.generalFValidation({
        required: true,
        name: "forma de pago",
      }),
      paymentMethodRules: validations.generalMValidation({
        required: true,
        name: "método de pago",
      }),
      cfdiRules: validations.generalMValidation({
        required: true,
        name: "CFDI",
      }),
      fiscalRegimenRules: validations.generalMValidation({
        required: true,
        name: "Regimen Fiscal",
      }),
      emailRules: validations.generalMValidation({
        required: true,
        name: "e-mail",
      }),
    };
  },
  watch: {
    "billingData.orderId": async function (newVal) {
      // 61eb242213e3bf49f8071a52
      if (ObjectId.isValid(newVal)) {
        await this.getOrderInformation(newVal);
      } else {
        this.validOrderId = "INVALID";
      }
    },
    "billingData.taxId": function (newVal) {
      if (this.billingData.taxId) {
        this.billingData.taxId = newVal.toUpperCase();
      }
    },
  },
  methods: {
    ...mapMutations("notification", ["show"]),
    ...mapMutations("loader", ["loading", "loaded"]),

    async getOrderInformation(id) {
      let response = null;

      try {
        this.loading();
        response = await this.axios.get(
          Endpoints.invoicesOrder.replace(/:orderId/, id)
        );
        console.log(response);
      } catch (error) {
        let message = "";
        if (error.response && error.response.data) {
          message = error.response.data.error.message;
        } else {
          message = "Ha ocurrido un problema al generar la factura";
        }
        this.show({
          text: message,
          color: "error",
          time: 10000,
        });
      } finally {
        if (response) {
          if (response.data.order) {
            console.log(response);
            let { email, paymentMethod } =
              response.data.order.paymentInformation;
            let { event, affiliation, fiscalData } = response.data.order;
            if (paymentMethod == "CREDIT_CARD") {
              paymentMethod = "";
              this.disablePaymentMethod = false;
            } else {
              this.disablePaymentMethod = true;
            }
            if (fiscalData) {
              console.log(fiscalData);
              if (
                fiscalData.hasOwnProperty("interior") &&
                fiscalData.interior != null
              ) {
                fiscalData.interior = fiscalData.interior.toString();
              }
              if (
                fiscalData.hasOwnProperty("exterior") &&
                fiscalData.exterior != null
              ) {
                fiscalData.exterior = fiscalData.exterior.toString();
              }
            }
            this.billingData = {
              ...this.billingData,
              email,
              paymentMethod,
              ...fiscalData,
            };

            this.validOrderId = "VALID";
            this.mainErrorMessage = "";
          }
        }
        this.loaded();
      }
    },
    async createInvoice() {
      if (!this.$refs.billingForm.validate()) return;
      try {
        this.loading();
        const { orderId: id, paymentMethod, ...rest } = this.billingData;
        const payload = {
          order: {
            id,
            paymentMethod,
          },
          customer: { ...rest },
        };
        let response = await Vue.axios({
          method: "POST",
          url: Endpoints.invoicesCreate,
          data: payload,
        });
        if (response) {
          const { status } = response.data;
          // Reiniciar el formulario de facturación
          this.validOrderId = "UNDEFINED";
          this.$refs.billingForm.reset();
          this.show({
            text: "Factura 4.0 se ha enviado al correo ingresado",
            color: "primary",
          });
        }
      } catch (error) {
        let message = "";
        if (error.response && error.response.data) {
          message = error.response.data.error.message;
        } else {
          message = "Ha ocurrido un problema al generar la factura";
        }

        this.show({
          text: message,
          color: "error",
          time: 10000,
        });
      } finally {
        this.loaded();
      }
    },
  },
  async mounted() {
    //console.log(this.$route.params.orderId);
    this.billingData.orderId = this.$route.params.orderId;
    if (ObjectId.isValid(this.billingData.orderId)) {
      await this.getOrderInformation(this.billingData.orderId);
    }
  },
};
</script>
<style scoped>
.confirm-button {
  font-weight: 800;
  text-transform: capitalize;
}
</style>
